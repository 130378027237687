import TagsServiceClient from "@seneca/tags-service-client";

const client = new TagsServiceClient(fetch, {
  protocol: "https",
  host: "tags.app.senecalearning.com",
  credentials: {},
  getUserRegion: () => "GB"
});

export default client;
