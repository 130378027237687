import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { HandleFailedFetch, Spinner } from "../../../common/";
import TickSubjectsSection from "./TickSubjectsSection";
import PillMenu from "./PillMenu";
import "./ChooseSubjectsSection.css";

const ChooseSubjectsSection = ({
  fetching,
  freeCourses,
  fetchError,
  checkedSubjectIDs,
  handleTickEvent,
  changeActiveMenuItem,
  activeMenuItem,
  disabled
}) => {
  const maxCourses = 49;
  const showSubjectsWarning = checkedSubjectIDs.length > maxCourses;
  return (
    <HandleFailedFetch fetching={fetching} fetchError={fetchError}>
      <React.Fragment>
        <PillMenu
          changeActiveMenuItem={changeActiveMenuItem}
          activeMenuItem={activeMenuItem}
          menuItems={Object.keys(freeCourses)}
        />
        <div
          className={classNames("ChooseSubjectsSection__SubjectsSelection", {
            ChooseSubjectsSection__Warning: showSubjectsWarning
          })}
        >
          {checkedSubjectIDs.length} subjects selected{" "}
          {showSubjectsWarning
            ? `(Please select less than ${maxCourses + 1} courses)`
            : null}
        </div>
        {Object.keys(freeCourses).map(key => {
          if (key === activeMenuItem) {
            return (
              <TickSubjectsSection
                ageGroup={key}
                subjects={freeCourses[key]}
                key={key}
                checkedSubjectIDs={checkedSubjectIDs}
                handleTickEvent={handleTickEvent}
              />
            );
          }
        })}
      </React.Fragment>
    </HandleFailedFetch>
  );
};

ChooseSubjectsSection.propTypes = {
  fetchError: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  handleTickEvent: PropTypes.func.isRequired,
  changeActiveMenuItem: PropTypes.func.isRequired,
  activeMenuItem: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ChooseSubjectsSection;
