import CourseServiceClient from "@seneca/course-service-client";

const client = new CourseServiceClient(fetch, {
  protocol: "https",
  host: "course-cdn-v2.app.senecalearning.com",
  credentials: {},
  getUserRegion: () => "GB"
});

export default client;
