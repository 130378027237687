import React from "react";
import QRCode from "qrcode.react";
import "./InstructionsBox.css";
import { language } from "../consts";

const BLACK_LOGO_LINK =
  "https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/senecaBlackLogo.svg";

const JOIN_CLASS_LINK = "app.senecalearning.com/join-class";
const JOIN_CLASS_DASHBOARD_LINK = "app.senecalearning.com/dashboard/join-class";

const InstructionsBox = ({ classId, teacherName, className, locale }) => {
  return (
    <div className="InstructionsBox__container">
      <div className="InstructionsBox__logoContainer">
        <img src={BLACK_LOGO_LINK} className="InstructionsBox__logo" />
      </div>
      <div className="InstructionsBox__title">
        {`${language[locale].howTo} ${teacherName}${
          locale === "en" ? language[locale].class : ""
        }: ${className}`}
      </div>
      <ol>
        <li>
          {language[locale].step1}
          {": "}
          <span className="InstructionsBox__impText">{JOIN_CLASS_LINK}</span>
        </li>
        <li>{language[locale].step2}</li>
        <li>
          {language[locale].step3}
          {": "}
          <span className="InstructionsBox__impText">{classId}</span>
        </li>
      </ol>
      <div className="InstructionsBox__title qrDesc">
        {language[locale].qrCodeInstruction}
      </div>
      <QRCode
        value={`${JOIN_CLASS_DASHBOARD_LINK}/${classId}`}
        size={200}
        renderAs="svg"
      />
    </div>
  );
};

export default InstructionsBox;
