import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./PillMenu.css";

const PillMenu = ({ changeActiveMenuItem, activeMenuItem, menuItems }) => {
  return (
    <div className="PillMenu__Container">
      {menuItems.map(menuItem => {
        return (
          <div
            key={menuItem}
            onClick={() => changeActiveMenuItem(menuItem)}
            className={classNames("PillMenu__Item", {
              PillMenu__Active: menuItem === activeMenuItem
            })}
          >
            {menuItem}
          </div>
        );
      })}
    </div>
  );
};

export default PillMenu;
