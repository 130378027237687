import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import YourSchoolPage from "./pages/YourSchoolPage/YourSchoolPage";
import CreateYourSchoolPage from "./pages/CreateYourSchoolPage/CreateYourSchoolPage";
import PrintJoinClassInstructions from "./pages/PrintJoinClassInstructions/PrintJoinClassInstructions";
import TopicSearchPage from "./pages/TopicSearch/TopicSearchPage.js";
import TutoringPayment from "./pages/TutoringPayment/TutoringPayment";
import ReactGA from "react-ga";
import {
  pageTextUS,
  USA_SECTIONS_URL,
  pageTextMX,
  MX_SECTIONS_URL,
  pageTextBR,
  BR_SECTIONS_URL,
} from "./topicsData";

import "./App.css";

const App = () => {
  ReactGA.initialize("UA-115074029-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App_container">
      <Router>
        <Switch>
          <Route
            path="/create-school/:schoolName/:courseIDsArray"
            component={CreateYourSchoolPage}
          />
          <Route
            path="/your-school/:schoolName/:courseIDsArray"
            component={YourSchoolPage}
          />
          <Route
            path="/print-class-instructions/:locale/:teacherName/:classId/:className"
            component={PrintJoinClassInstructions}
          />
          <Route path="/tutoring/:customerEmail" component={TutoringPayment} />
          <Route path="/tutoring" component={TutoringPayment} />
          <Route
            path="/tutoring-success"
            component={() => <TutoringPayment success />}
          />
          <Route
            path="/us/topic-search"
            component={() => (
              <TopicSearchPage
                pageText={pageTextUS}
                url={USA_SECTIONS_URL}
                locale="US"
              />
            )}
          />
          <Route
            path="/us/search"
            component={() => (
              <TopicSearchPage
                pageText={pageTextUS}
                url={USA_SECTIONS_URL}
                locale="US"
              />
            )}
          />
          <Route
            path="/br/search"
            component={() => (
              <TopicSearchPage
                pageText={pageTextBR}
                url={BR_SECTIONS_URL}
                locale="BR"
                signUpLabel="Cadastrar"
              />
            )}
          />
          <Route
            path="/mx/search"
            component={() => (
              <TopicSearchPage
                pageText={pageTextMX}
                url={MX_SECTIONS_URL}
                signUpLabel="Cadastrar"
              />
            )}
          />
          <Route path="/" component={CreateYourSchoolPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
