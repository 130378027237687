import React from "react";
import {
  FetchFreeCourseDataHOC,
  PageWrapper,
  HandleFailedFetch,
} from "../../common";
import YourCoursesSection from "./components/YourCoursesSection";
import "./YourSchoolPage.css";

class YourSchoolPage extends React.Component {
  state = {
    selectedKey: null,
  };

  render() {
    const { match, fetchError, fetching, freeCourses } = this.props;
    const courseIDsArray = match.params.courseIDsArray.split(",");
    const { schoolName } = match.params;
    const schoolsCoursesObject = {};

    Object.keys(freeCourses).forEach((key) => {
      const courseArray = freeCourses[key];
      const courseArrayFiltered = courseArray.filter((course) => {
        return courseIDsArray.includes(course.id);
      });

      if (courseArrayFiltered.length > 0) {
        schoolsCoursesObject[key] = courseArrayFiltered;
      }
    });

    return (
      <PageWrapper className="YourSchoolPage__InnerContainer">
        <h2>{schoolName}</h2>

        <HandleFailedFetch fetchError={fetchError} fetching={fetching}>
          {this.state.selectedKey ? (
            <YourCoursesSection
              ageGroup={this.state.selectedKey}
              subjects={schoolsCoursesObject[this.state.selectedKey]}
              key={this.state.selectedKey}
              schoolName={schoolName}
              clearSelectedKey={() => this.setState({ selectedKey: null })}
            />
          ) : (
            <div>
              <h4>Choose a key stage to find courses</h4>
              <div className="YourSchoolPage__SelectAgeGroupContainer">
                {Object.keys(schoolsCoursesObject).map((key) => (
                  <div
                    onClick={() => this.setState({ selectedKey: key })}
                    className="YourSchoolPage__SelectAgeGroup"
                    key={key}
                  >
                    {key}
                  </div>
                ))}
              </div>
            </div>
          )}
        </HandleFailedFetch>
      </PageWrapper>
    );
  }
}

export default FetchFreeCourseDataHOC(YourSchoolPage);
