import React from "react";
import PropTypes from "prop-types";
import { BodyContainer, HeaderSection } from "./index";

const PageWrapper = ({
  children,
  className,
  signUpLabel,
  showSignUpButton,
}) => {
  return (
    <>
      <HeaderSection
        signUpLabel={signUpLabel}
        showSignUpButton={showSignUpButton}
      />
      <BodyContainer innerClassName={className}>{children}</BodyContainer>
    </>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { PageWrapper };
