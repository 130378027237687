import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./BodyContainer.css";

const BodyContainer = ({ children, outerClassName, innerClassName }) => {
  return (
    <div className={classNames("BodyContainer__Outer", outerClassName)}>
      <div className={classNames("BodyContainer__Inner", innerClassName)}>
        {children}
      </div>
    </div>
  );
};

BodyContainer.propTypes = {
  children: PropTypes.node.isRequired,
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string
};

export { BodyContainer };
