export const REGIONS = ["en", "pt-BR", "es-MX", "fr"];

export const language = {
  en: {
    howTo: "To join",
    class: "'s class",
    instruction:
      "Print class invite cards for your students to help them join your class",
    printText: "Print",
    step1: "Go to the website",
    step2: "Sign up as a student",
    step3: "Type in the class code",
    qrCodeInstruction: "Or scan the QR code with your phone"
  },
  fr: {
    howTo: "Comment s’inscrire à la classe de",
    instruction:
      "Imprimez des cartes d’invitation pour que vos étudiants puissent s’inscrire à votre classe.",
    printText: "Imprimez",
    step1: "Allez sur le site",
    step2: "Créez un compte d’étudiant",
    step3: "Tapez votre code de classe",
    qrCodeInstruction: "Ou scanne le QR code avec ton téléphone"
  },
  "pt-BR": {
    howTo: "Como entrar na turma de",
    instruction:
      "Imprima cartões-convite para seus alunos para ajudá-los a entrar na sua turma",
    printText: "Imprimir",
    step1: "Vá no site",
    step2: "Crie uma conta de estudante",
    step3: "Digite o código",
    qrCodeInstruction: "Ou tire uma foto do QR code abaixo com seu celular"
  },
  "es-MX": {
    howTo: "Como unirse a",
    instruction:
      "Imprime invitaciones para tus estudiantes para ayudarles a unirse a tu clase",
    printText: "Imprimir",
    step1: "Visita la página web:",
    step2: "Crea una cuenta de estudiante",
    step3: "Escribe el código de clase",
    qrCodeInstruction: "O escanea el código QR con tu teléfono móvil"
  }
};
