import React from "react";
import PropTypes from "prop-types";
import Intercom from "react-intercom";
import { PageWrapper, FetchFreeCourseDataHOC } from "../../common";
import ChooseSubjectsSection from "./component/ChooseSubjectsSection";
import ShareLinkSection from "./component/ShareLinkSection";
import "./CreateYourSchoolPage.css";

const ROUTE_PATH_HOSTED = "https://your-school.senecalearning.com/#";
const ROUTE_PATH = "http://localhost:3000/#";

class CreateYourSchoolPage extends React.Component {
  state = {
    schoolName: "",
    checkedSubjectIDs: [],
    activeMenuItem: "KS2",
  };

  componentDidMount() {
    const { courseIDsArray, schoolName } = this.props.match.params;
    if (courseIDsArray && schoolName) {
      const splitCourseIdsArray = courseIDsArray.split(",");
      if (splitCourseIdsArray.length > 0) {
        this.setState({ checkedSubjectIDs: splitCourseIdsArray });
      }
      if (schoolName.length > 0) {
        this.setState({ schoolName });
      }
    }
  }

  changeActiveMenuItem(activeMenuItem) {
    this.setState({ activeMenuItem });
  }

  updateField(field, update) {
    this.setState({ [field]: update });
  }

  handleTickEvent(courseID) {
    const { checkedSubjectIDs } = this.state;
    if (checkedSubjectIDs.includes(courseID)) {
      const newcheckedSubjectIDs = checkedSubjectIDs.filter((id) => {
        return id !== courseID;
      });

      this.setState({ checkedSubjectIDs: newcheckedSubjectIDs });
    } else {
      this.setState({ checkSubjectIDs: checkedSubjectIDs.push(courseID) });
    }
  }

  generateYourSchoolLink() {
    const { schoolName, checkedSubjectIDs } = this.state;

    if (schoolName.length > 0 && checkedSubjectIDs.length > 0) {
      return `${ROUTE_PATH_HOSTED}/your-school/${this.state.schoolName}/${
        this.state.checkedSubjectIDs
      }`;
    }

    return false;
  }

  render() {
    const { fetchError, fetching, freeCourses } = this.props;
    const { schoolName } = this.state;
    return (
      <>
        <Intercom appID="fwcflxs6" />
        <PageWrapper className="CreateYourSchoolPage__BodyInnerContainer">
          <h2>Create a Custom Seneca Page For Your School</h2>
          <p>
            You can use this tool to only show your students courses relevant to
            them.{" "}
            <a
              href="https://help.senecalearning.com/en/articles/2834890-create-a-custom-seneca-page-for-your-school"
              target="_blank"
              rel="noopener noreferrer"
            >
              Check out this guide
            </a>{" "}
            if you need help setting up.
          </p>

          <h3>Share this link with your students</h3>
          <p>
            Your students will only see the courses you have selected below:
          </p>
          <ShareLinkSection
            shareLink={this.generateYourSchoolLink()}
            schoolName={schoolName}
            topOfPage
          />

          <h3>
            Embed on your school website - share this snippet with your school
            IT technician
          </h3>
          <p>
            Your IT technician can use this snippet to embed your custom page on
            your school website:
          </p>
          <ShareLinkSection
            isEmbed
            shareLink={this.generateYourSchoolLink()}
            schoolName={schoolName}
            topOfPage
          />

          <h3>1. Enter your school's name</h3>
          <input
            type="text"
            placeholder="Your school name..."
            className="CreateYourSchoolPage__Input"
            value={this.state.schoolName}
            onChange={(e) => this.updateField("schoolName", e.target.value)}
          />
          <h3>2. Tick the subjects you want to be visible to your students</h3>
          <ChooseSubjectsSection
            fetchError={fetchError}
            fetching={fetching}
            freeCourses={freeCourses}
            checkedSubjectIDs={this.state.checkedSubjectIDs}
            handleTickEvent={this.handleTickEvent.bind(this)}
            changeActiveMenuItem={this.changeActiveMenuItem.bind(this)}
            activeMenuItem={this.state.activeMenuItem}
          />

          <h3>3. Share this link with your students</h3>
          <p>
            Your students will only see the courses you have selected below:
          </p>
          <ShareLinkSection
            shareLink={this.generateYourSchoolLink()}
            schoolName={schoolName}
          />

          <h3>
            4. Embed on your school website - share this snippet with your
            school IT technician
          </h3>
          <p>
            Your IT technician can use this snippet to embed your custom page on
            your school website:
          </p>
          <ShareLinkSection
            isEmbed
            shareLink={this.generateYourSchoolLink()}
            schoolName={schoolName}
          />
        </PageWrapper>
      </>
    );
  }
}

CreateYourSchoolPage.propTypes = {
  fetchError: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default FetchFreeCourseDataHOC(CreateYourSchoolPage);
