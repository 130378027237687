import React from "react";
import courseService from "../services/courseService.js";
import tagsService from "../services/tagsService.js";

const FetchFreeCourseDataHOC = WrappedComponent =>
  class extends React.Component {
    state = {
      fetching: false,
      fetchError: false,
      ks2Courses: [],
      ks3Courses: [],
      gcseCourses: [],
      alevelCourses: []
    };

    componentDidMount() {
      this.setState({ fetching: true });
      tagsService
        .fetchAll()
        .then(tagsObj => {
          const primaryTag = tagsObj.tags.find(tag => tag.text === "Primary");
          const ks3Tag = tagsObj.tags.find(tag => tag.text === "KS3");
          const gcseTag = tagsObj.tags.find(tag => tag.text === "GCSE");
          const alevelTag = tagsObj.tags.find(tag => tag.text === "A Level");
          const levelTag = tagsObj.tags.find(tag => tag.text === "Age Group");

          courseService
            .fetchCourseDetails()
            .then(courseDetails => {
              const publicFreeCourses = courseDetails.courses.filter(
                course =>
                  course.visibility === "PUBLIC" &&
                  course.subscriptionTier === "FREE"
              );

              const freeCoursesWithLevelTags = publicFreeCourses.filter(
                course => course.tags[levelTag.id]
              );

              const simplifiedFreeCourseArray = freeCoursesWithLevelTags.map(
                course => {
                  // filter out courses without levels

                  const tags = course.tags[levelTag.id].map(tag => tag.id);

                  return {
                    tags,
                    name: course.name,
                    img: course.headerImageURL,
                    id: course.courseId
                  };
                }
              );

              this.setState({
                ks2Courses: this.sortCoursesByTag(
                  primaryTag,
                  simplifiedFreeCourseArray
                ),
                ks3Courses: this.sortCoursesByTag(
                  ks3Tag,
                  simplifiedFreeCourseArray
                ),
                gcseCourses: this.sortCoursesByTag(
                  gcseTag,
                  simplifiedFreeCourseArray
                ),
                alevelCourses: this.sortCoursesByTag(
                  alevelTag,
                  simplifiedFreeCourseArray
                ),
                fetchError: false,
                fetching: false
              });
            })
            .catch(error => {
              console.log(error);
              this.setState({ fetchError: true, fetching: false });
            });
        })
        .catch(error => {
          console.log(error);
          this.setState({ fetchError: true, fetching: false });
        });
    }

    sortCoursesByTag(tag, courses) {
      return this.sortAlphabeticallyByName(
        courses.filter(course => course.tags.includes(tag.id))
      );
    }

    sortAlphabeticallyByName(objArray) {
      return objArray.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
    }

    render() {
      const {
        fetching,
        fetchError,
        ks2Courses,
        ks3Courses,
        gcseCourses,
        alevelCourses
      } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          fetching={fetching}
          fetchError={fetchError}
          freeCourses={{
            KS2: ks2Courses,
            KS3: ks3Courses,
            GCSE: gcseCourses,
            "A Level": alevelCourses
          }}
        />
      );
    }
  };

export { FetchFreeCourseDataHOC };
