import React from "react";
import "./PrintJoinClassInstructions.css";
import { PageWrapper, Button } from "../../common";
import InstructionsBox from "./components/InstructionsBox";
import { language, REGIONS } from "./consts";
import ReactGA from "react-ga";

const PrintJoinClassInstructions = ({ match }) => {
  const { teacherName, classId, className } = match.params;
  let { locale } = match.params;
  const CARDS_PER_PAGE = 4;

  if (!REGIONS.includes(locale)) {
    locale = "en";
  }

  return (
    <PageWrapper
      className="PrintJoinClassInstructions__BodyInnerContainer"
      showSignUpButton={false}
    >
      <div className="PrintJoinClassInstructions__Header">
        <h3>{language[locale].instruction}</h3>
        <Button
          label={language[locale].printText}
          onClick={() => {
            window.print();
            ReactGA.event({
              category: "Your school page",
              action: "Print instructions",
              label: `Print text: ${language[locale].printText}`,
            });
          }}
          className="PrintJoinClassInstructions__Button"
        />
      </div>

      <div className="PrintJoinClassInstructions__PrintContainer">
        <div className="PrintJoinClassInstructions__InstructionCardContainer">
          {[...Array(CARDS_PER_PAGE)].map((e, i) => {
            return (
              <InstructionsBox
                teacherName={teacherName}
                classId={classId}
                className={className}
                locale={locale}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </PageWrapper>
  );
};

export default PrintJoinClassInstructions;
