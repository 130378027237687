import React from "react";
import PropTypes from "prop-types";
import "./SubjectCheckBoxRow.css";

const SubjectCheckBoxRow = ({ checked, onChange, subjectName }) => {
  return (
    <label className="SubjectCheckBoxRow__Container">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark" />
      {subjectName}
    </label>
  );
};

SubjectCheckBoxRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  subjectName: PropTypes.string.isRequired
};

export default SubjectCheckBoxRow;
