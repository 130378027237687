import React from "react";
import { Helmet } from "react-helmet";
import { PageWrapper, Button } from "../../common";
import { loadStripe } from "@stripe/stripe-js";
import "./TutoringPayment.css";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_11CE1Pa7Fy7Fmg0fNv98clBm");

class TutoringPayment extends React.Component {
  state = {
    submitting: false,
  };

  handleClick() {
    (async () => {
      this.setState({ submitting: true });
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        items: [{ plan: "plan_H91SDlChMhrLdJ", quantity: 1 }],
        successUrl: "https://your-school.senecalearning.com/#/tutoring-success",
        cancelUrl: "https://your-school.senecalearning.com/#/tutoring",
        customerEmail: this.props.match.params.customerEmail,
      });
    })();
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Seneca - Tutoring</title>
        </Helmet>
        <PageWrapper className="TutoringPayment__Container">
          {this.props.success ? (
            <p>
              Success! You have claimed your place in the trial. If you have any
              questions don't hesitate to contact{" "}
              <a href="mailto:jack@seneca.io">jack@seneca.io</a>
            </p>
          ) : (
            <>
              <p>
                Click ‘Continue’ and then enter your card details to confirm
                your child’s place in the Seneca Online Tutoring 2-week free
                trial. If you don’t want your child to keep receiving unlimited
                schoolwork support after the trial, please email{" "}
                <a href="mailto:jack@seneca.io">jack@seneca.io</a>.
              </p>
              <Button
                className="TutoringPayment__Button"
                onClick={() => this.handleClick()}
                label={this.state.submitting ? "Loading..." : "Continue"}
              />
            </>
          )}
        </PageWrapper>
      </>
    );
  }
}

export default TutoringPayment;
