import React from "react";
import PropTypes from "prop-types";
import "./WarningMessage.css";

const WarningMessage = ({ message }) => {
  return <div className="WarningMessage__Container">{message}</div>;
};

WarningMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export { WarningMessage };
