import React from "react";
import "./Spinner.css";

const Spinner = () => {
  return (
    <div className="Spinner__Container">
      <div className="loading">
        <div className="loading-bar" />
        <div className="loading-bar" />
        <div className="loading-bar" />
        <div className="loading-bar" />
      </div>
    </div>
  );
};

export { Spinner };
