import React from "react";
import classNames from "classnames";
import "./HeaderSection.css";

const ICON_LINK =
  "https://www.senecalearning.com/?utm_source=referral&utm_medium=school-widget";
const ICON_LOGO =
  "https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/senecaWhiteLogo.svg";

const SIGN_UP_LINK = "https://app.senecalearning.com/sign-up";

const HeaderSection = ({ signUpLabel, showSignUpButton = true }) => {
  return (
    <div
      className={classNames("HeaderSection__NavbarContainer", {
        HeaderSection__NoSignup: !showSignUpButton,
      })}
    >
      <a href={ICON_LINK} className={"HeadSection__LogoContainer"}>
        <img src={ICON_LOGO} className="HeaderSection__Logo" />
      </a>
      {showSignUpButton ? (
        <a
          href={SIGN_UP_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className="HeaderSection__Button"
        >
          {signUpLabel}
        </a>
      ) : null}
    </div>
  );
};

HeaderSection.defaultProps = {
  signUpLabel: "Sign up",
};

export { HeaderSection };
