import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import "./CourseCard.css";

class CourseCard extends React.Component {
  getImgExtension(imgURL) {
    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(imgURL);
  }

  generateImageLink(img) {
    // gets a url to a smaller version of the image
    const imgURLReplaceCDN = img.replace(
      "seneca-image-cdn:///",
      "https://image.cdn.app.senecalearning.com/"
    );
    const imgExtension = this.getImgExtension(imgURLReplaceCDN);
    const imgURLWithoutExtension = imgURLReplaceCDN.replace(
      imgExtension[0],
      ""
    );
    return `${imgURLWithoutExtension},c_center,h_454${imgExtension[0]}`;
  }

  render() {
    const { name, img, id, schoolName } = this.props;

    return (
      <a
        href={`https://app.senecalearning.com/classroom/course/${id}/new-session?utm_source=referral&utm_medium=school-widget`}
        target="_blank"
        rel="noopener noreferrer"
        className="CourseCard__Container"
        onClick={() =>
          ReactGA.event({
            category: "Your school page",
            action: `Clicked course card! - ${schoolName}`
          })
        }
      >
        <img className="CourseCard__Image" src={this.generateImageLink(img)} />
        <p>{name}</p>
      </a>
    );
  }
}

CourseCard.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default CourseCard;
