import React from "react";
import Fuse from "fuse.js";
import ReactGA from "react-ga";
import axios from "axios";
import { Helmet } from "react-helmet";
import { PageWrapper } from "../../common";

import "./TopicSearchPage.css";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  distance: 50,
  maxPatternLength: 12,
  minMatchCharLength: 3,
  keys: ["title", "subject", "ageGroup"],
};

const EVENT_CATEGORY = "YOUR_SENECA_TOPIC_SEARCH";

class TopicSearchPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      fetching: true,
      fetchError: false,
      topicClicked: false,
      searchEventFired: false,
      data: [],
    };

    this.fuse = null;
  }

  componentDidMount() {
    axios
      .get(this.props.url)
      .then((res) => {
        this.setState({
          fetching: false,
          fetchError: false,
        });

        this.fuse = new Fuse(res.data, fuseOptions);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ fetching: false, fetchError: true });
      });
    ReactGA.event({
      category: EVENT_CATEGORY,
      action: `${EVENT_CATEGORY} - ${this.props.locale}`,
      label: `PAGE VIEW ${this.props.locale}`,
    });
  }

  handleTextChange(searchText) {
    if (!this.state.searchEventFired) {
      ReactGA.event({
        category: EVENT_CATEGORY,
        action: `${EVENT_CATEGORY} - ${this.props.locale}`,
        label: `TOPIC SEARCH ${this.props.locale}`,
      });
      this.setState({ searchEventFired: true });
    }

    const searchResults = this.fuse.search(searchText);
    this.setState({ searchText, data: searchResults.slice(0, 25) });
  }

  handleTopicClick() {
    if (!this.state.topicClicked) {
      ReactGA.event({
        category: EVENT_CATEGORY,
        action: `${EVENT_CATEGORY} - ${this.props.locale}`,
        label: `TOPIC CLICK ${this.props.locale}`,
      });
      this.setState({ topicClicked: true });
    }
    ReactGA.event({
      category: EVENT_CATEGORY,
      action: `Search result text - ${this.props.locale}`,
      label: this.state.searchText,
    });
  }

  renderTopics() {
    const { searchText, fetchError, data } = this.state;
    const { pageText } = this.props;

    if (fetchError) {
      return (
        <div className="TopicSearchPage__SearchResultContainer">
          {pageText.error}
        </div>
      );
    } else if (searchText.length > 0 && data.length > 0) {
      return (
        <ul className="TopicSearchPage__SearchResultContainer">
          {data.map((item, i) => {
            const { title, subject, ageGroup, url } = item.item;
            return (
              <li
                key={`${i} - ${title}`}
                onClick={() => this.handleTopicClick()}
              >
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {title} - {subject} - {ageGroup}
                </a>
              </li>
            );
          })}
        </ul>
      );
    } else if (searchText.length > 0 && data.length === 0) {
      return (
        <div className="TopicSearchPage__SearchResultContainer">
          {pageText.noResults}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { searchText, fetching } = this.state;
    const { pageText } = this.props;
    return (
      <>
        <Helmet>
          <title>{pageText.inputPlaceholder}</title>
        </Helmet>
        <PageWrapper
          className="TopicSearchPage__BodyInnerContainer"
          signUpLabel={this.props.signUpLabel}
        >
          <input
            type="text"
            disabled={fetching}
            placeholder={
              fetching ? pageText.loading : pageText.inputPlaceholder
            }
            className="TopicSearchPage__Input"
            value={searchText}
            onChange={(e) => this.handleTextChange(e.target.value)}
          />
          {this.renderTopics()}
        </PageWrapper>
      </>
    );
  }
}

export default TopicSearchPage;
