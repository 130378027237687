import React from "react";

import CourseCardContainer from "./CourseCardContainer";
import CourseCard from "./CourseCard";
import "./YourCoursesSection.css";

const YourCoursesSection = ({
  ageGroup,
  subjects,
  schoolName,
  clearSelectedKey,
}) => {
  return (
    <div className="YourCoursesSection__Container">
      <div className="YourCoursesSection__Header">
        <div
          className="YourCoursesSection__ClearButton"
          onClick={() => clearSelectedKey()}
        >
          Back to key stage
        </div>
      </div>
      <CourseCardContainer>
        {subjects.map((subject) => {
          return (
            <CourseCard
              key={subject.id}
              name={subject.name}
              img={subject.img}
              id={subject.id}
              schoolName={schoolName}
            />
          );
        })}
      </CourseCardContainer>
    </div>
  );
};

export default YourCoursesSection;
