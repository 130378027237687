export const pageTextUS = {
  loading: "Loading...",
  error: "Sorry, something went wrong... Try reloading the page",
  noResults: "Sorry no topics match that search...",
  inputPlaceholder: "Search for a topic...",
};

export const USA_SECTIONS_URL =
  "https://d17hukvrbysd4e.cloudfront.net/topic-search-tool/usa/usa.json";

export const BR_SECTIONS_URL =
  "https://d17hukvrbysd4e.cloudfront.net/topic-search-tool/brazil/brazil.json";

export const pageTextBR = {
  loading: "Carregando...",
  error: "Desculpe, algo deu errado... Tente recarregar a página",
  noResults:
    "Desculpe, não há resultados para essa busca. Tente um termo diferente...",
  inputPlaceholder: "Pesquise por um termo...",
};

export const MX_SECTIONS_URL =
  "https://d17hukvrbysd4e.cloudfront.net/topic-search-tool/usa/usa.json";

export const pageTextMX = {
  loading: "Cargando…",
  error: "Disculpa, algo salió mal... intenta actualizar la página",
  noResults:
    "Disculpa, no hay resultados para esta búsqueda. Inténtalo con un término diferente...",
  inputPlaceholder: "Busca un tópico...",
};
