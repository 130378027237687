import React from "react";
import PropTypes from "prop-types";
import { Spinner, WarningMessage } from "./index";

const HandleFailedFetch = ({ fetching, fetchError, children }) => {
  if (fetching) {
    return <Spinner />;
  } else if (fetchError) {
    return (
      <WarningMessage message="Something went wrong! Try refreshing or get in touch if the problem persists." />
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

HandleFailedFetch.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export { HandleFailedFetch };
