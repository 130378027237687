import React from "react";
import SubjectCheckBoxRow from "./SubjectCheckBoxRow";

const TickSubjectsSection = ({
  subjects,
  checkedSubjectIDs,
  handleTickEvent
}) => {
  return (
    <>
      {subjects.map((subject, i) => {
        return (
          <div key={i}>
            <SubjectCheckBoxRow
              checked={checkedSubjectIDs.includes(subject.id)}
              onChange={() => handleTickEvent(subject.id)}
              subjectName={subject.name}
            />
          </div>
        );
      })}
    </>
  );
};

export default TickSubjectsSection;
