import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "../../../common";
import "./ShareLinkSection.css";

class ShareLinkSection extends React.Component {
  state = {
    shareButtonClicked: false,
  };

  generateCorrectShareLink() {
    const { topOfPage, shareLink, isEmbed } = this.props;
    if (!shareLink) {
      return `Enter your school name & choose which courses you want to appear ${
        topOfPage ? "below" : "above"
      } to ${
        isEmbed ? "generate this snippet to embed" : "generate this link"
      }.`;
    }

    if (isEmbed) {
      return `<iframe width="400" height="600" src="${shareLink}" frameborder="0" allowfullscreen><p>Your browser does not support iframes.</p></iframe>`;
    }

    return shareLink;
  }

  onCopy() {
    if (this.props.shareLink) {
      this.setState({ shareButtonClicked: true });
    }
  }

  render() {
    const { shareButtonClicked } = this.state;

    return (
      <CopyToClipboard
        text={this.generateCorrectShareLink()}
        onCopy={() => this.onCopy()}
      >
        <div className="ShareLinkSection__Container">
          <input
            className="ShareLinkSection__TextContainer"
            disabled
            value={this.generateCorrectShareLink()}
          />
          <Button
            label={shareButtonClicked ? "Copied!" : "Copy"}
            disabled={!this.props.shareLink}
          />
        </div>
      </CopyToClipboard>
    );
  }
}

ShareLinkSection.propTypes = {
  shareLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  isEmbed: PropTypes.bool,
  topOfPage: PropTypes.bool,
};

export default ShareLinkSection;
